<template>
  <div class="info_warp">
    <div class="title">术康-注册协议</div>
    <div class="inner">
      <p>术康手机客户端（以下简称“本软件”）由成都尚医信息科技有限公司（以下简称“尚医科技”）负责运营。请用户在注册成为本软件用户前务必仔细阅读以下条款。若用户一旦注册，则表示用户同意接受本软件的服务，并同意受以下条款所约束。本协议构成对双方有约束力的法律文件。
        尚医科技有权随时变更本协议并在本软件上予以公告。经修订的条款一经公告，立即自动生效。如您不同意相关变更，必须停止使用本软件；一旦您继续使用本软件，则表示您已接受并自愿遵守经修订后的本协议条款。</p>

      <h3>第1条 总则</h3>
      <p>1.1 本协议内容包括以下条款及本软件已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的一部分，与协议正文具有同等法律效力。本协议是由用户与本软件共同签订的，适用于用户在本软件的全部活动。在用户注册成为本软件用户时，用户确认已经阅读、理解并接受本协议的全部条款及各类规则，并承诺遵守中国现行的法律、法规、规章及其他政府规定，如有违反而导致任何法律后果的发生，用户将独立承担相应的法律责任。</p>
      <p>1.2 在满足如下条件之一时，才可申请成为本软件用户，可接受本软件提供的服务：</p>
      <p>（1）年满十八周岁，并具有民事权利能力和民事行为能力的自然人；</p>
      <p>（2）无民事行为能力人或限制民事行为能力人经过其监护人的同意和监督。</p>
      <p>若无民事行为能力人或限制民事行为能力人未经其监护人同意，或未经其监护人监督使用本软件的，则其与尚医科技之间的本协议自始无效。尚医科技一经发现，有权立即终止对该用户的服务，且对其已使用的服务内容不承担任何责任。</p>
      <p>1.3 用户点击同意本协议时，即视为用户已确认自己具有享受本软件服务、上传用户信息等相应的权利能力和行为能力，能够独立承担法律责任。</p>
      <p>1.4 本软件保留在法律允许的范围内单独决定拒绝服务、关闭用户账户、清除或编辑内容及取消订单的权利</p>

      <h3>第2条 使用限制</h3>
      <p>2.1 本软件中的全部内容的知识产权（包括但不限于商标权、专利权、著作权、商业秘密等）均属于本软件提供方尚医科技所有，该等内容包括但不限于文本、数据、文章、设计、源代码、软件、图片、照片及其他全部信息（以下称“软件内容”）。本软件内容受中华人民共和国著作权法、各国际版权公约以及其他知识产权法律法规与公约的保护。未经本软件事先书面同意，用户承诺不以任何方式复制、模仿、传播、出版、公布、展示软件内容，包括但不限于电子的、机械的、复印的、录音录像的方式和形式等。未经本软件书面同意，用户亦不得将本软件包含的资料等任何内容镜像到任何其他软件或者服务器。任何未经授权对软件内容的使用均属于违法行为，尚医科技将追究法律责任。</p>
      <p>2.2 用户承诺合法使用本软件提供的服务及软件内容。禁止在本软件从事任何可能违反中国现行的法律、法规、规章和政府规范性文件的行为或者任何未经授权使用本软件的行为，如擅自进入本软件的未公开的系统、不正当的使用密码和软件的任何内容等。</p>
      <p>2.3 用户注册成功后，不得将本软件的账户转让给第三方或者授权给第三方使用。本软件通过用户的账户及密码来识别用户的指令。用户确认，使用用户的账户和密码登录本软件后在本软件的一切行为均代表用户本人意志。使用用户的账户和密码登陆操作所产生的电子信息记录均为用户行为的有效凭据，并由用户承担相应的法律后果。</p>
      <p>2.4 用户有义务在注册时提供自己的最新、真实、有效、完整的资料，并保证诸如电子邮件地址、联系电话、联系地址、邮政编码、身份证号等内容的有效性及安全性。用户有义务维持并立即更新用户的注册资料，确保其为真实、最新、有效及完整。若用户提供任何错误、虚假、过时或不完整的资料，或者本软件有合理的理由怀疑资料为错误、虚假、过时或不完整，本软件保留终止用户使用本软件各项服务的权利。</p>
      <p>2.5 用户不得以任何形式擅自转让或授权他人使用自己在本软件的用户名、账号。</p>
      <p>2.6 用户不得经由非本软件所提供的界面使用本软件。</p>
      <p>2.7 用户注册成功后，同意本软件可通过邮件、短信、电话等形式向用户发送与医疗健康相关的资讯、服务、产品、促销活动等信息。</p>

      <h3>第3条 隐私保护</h3>
      <p>3.1 保护用户的隐私是本软件的一项基本政策，用户隐私包括个人隐私和非个人隐私。个人隐私信息指可以用于对用户进行个人辨识或涉及个人通信的信息，包括：真实姓名、有效身份证号、手机号码、联系地址、病患信息等。非个人隐私信息指用户在使用本软件时的操作状态、设备信息以及使用习惯等明确客观记录用户在本软件服务器端的基本记录信息，和其他个人隐私信息范围外的普通信息。本软件不对外公开或向第三方提供用户的注册资料及用户在使用本软件服务时存储在软件的个人隐私信息，但下列情况除外：</p>
      <p>（1）事先获得用户或用户监护人的明确授权；</p>
      <p>（2）根据有关的法律法规要求；</p>
      <p>（3）按照相关政府主管部门的要求；</p>
      <p>（4）为维护社会公众的利益；</p>
      <p>（5）为维护软件的合法权益。</p>
      <p>3.2 本软件可能会与第三方合作向用户提供相关的服务，在此情况下，如该第三方同意承担不低于本软件的隐私保护责任，用户同意并授权本软件将用户的相关资料等提供给该第三方。</p>
      <p>3.3 若用户发现自己的个人信息未经授权、法律法规明确要求或通过其他非法途径泄露的，请用户立即联系本软件客服【客服电话】，以便采取相应措施。</p>
      <p>3.4 在不泄露具体、单个用户隐私资料的前提下，用户同意本软件对整个用户数据库进行整体分析并对用户数据库进行商业上的利用，包括出售用户数据库中的信息。</p>
      <p>3.5 本软件非常重视信息安全，成立了专门团队，研发和应用多种安全技术和程序等措施严格保护用户的信息不被未经授权的访问、使用或泄漏。但用户在使用本软件时应确保其网络接入点的安全性，本软件无法承担因用户自身使用不安全网络接口导致的信息安全责任。</p>
      <p>3.6 关于个人信息保护的具体内容详见《隐私政策》。</p>

      <h3>第4条 责任限制</h3>
      <p>4.1 用户个人对网络服务的使用承担风险，以及因使用网络服务而产生的一切后果。本软件及尚医科技对此不作任何类型的担保。</p>
      <p>4.2 本软件及尚医科技不保证所提供的服务一定能完全满足用户的要求，也不保证各项服务不会受网络原因而中断，对服务的及时性、安全性、错误程序的发生均不作担保。</p>
      <p>4.3 本软件及尚医科技不保证用户一定能从本软件得到用户所需的口头或书面的意见或信息。</p>
      <p>4.4 本软件及尚医科技对用户使用网络服务而受到的任何直接或间接的损害（本软件违反法律、法规和本协议的条款除外）不承担责任。</p>
      <p>4.5 本软件及尚医科技对用户因用户名、密码及设备遗失、被盗或类似情形而受到的任何直接或间接的损失不承担责任。</p>
      <p>4.6 因发生如火灾、水灾、暴动、骚乱、战争、黑客攻击、网络病毒、电信部门技术管制、政府行为或其他任何自然或认为造成的灾难等不可抗力事件影响软件提供服务，本软件及尚医科技不承担任何责任。</p>
      <p>4.7 本软件所有信息仅供参考，不应作为个别诊断、用药和使用的依据。本软件致力于提供正确、完整的健康资讯，但不保证信息的绝对正确性和完整性，且不对因信息的不正确或遗漏导致的任何损失或损害承担责任。本软件所提供的任何医药资讯仅供参考，不能替代医生和其他医务人员的建议，如自行使用本网站资料发生偏差，本软件及尚医科技不承担任何法律责任。</p>
      <p>4.8 用户同意，在使用本软件时，应(1)佩戴心率带；(2)听从本软件中的处方提示，包括声音和画面提示；(3)定期按本软件中的提示进行评测。若因用户在使用本软件过程中未遵守上述使用要求导致的任何直接或间接的损害、损伤或损失，本软件及尚医科技不承担任何责任。</p>
      <p>4.9 若用户在运动中产生任何疼痛或损伤，应即刻停止运动，并立即联系本软件中的主管医生。若因用户未及时停止或未立即联系主管医生寻求解决方案导致的任何直接或间接的损害、损伤或损失，本软件及尚医科技不承担任何责任。</p>

      <h3>第5条 通知</h3>
      <p>本协议条款及任何其他的协议、告示或其他关于用户使用本软件服务的通知，用户同意本软件使用电子方式通知用户。电子方式包括但不限于以电子邮件方式、在本软件或者合作软件上公布、以无线通讯装置通知等方式。用户同意，本软件以电子方式发出前述通知之日视为通知已送达。因信息传输等原因导致用户未在前述通知发出当日收到该等通知的，本软件及尚医科技不承担责任。</p>

      <h3>第6条 终止</h3>
      <p>6.1 除非本软件终止本协议或者用户申请终止本协议且经本软件及尚医科技同意，否则本协议始终有效。本软件有权在不通知用户的情况下在任何时间终止本协议或者限制用户使用本软件。但本软件的终止行为不能免除用户根据本注册协议或在本软件生成的其他协议项下的还未履行完毕的义务。</p>
      <p>6.2 若用户申请终止本软件之使用、服务，需经本软件及尚医科技审核同意，方可解除服务协议，但本软件及尚医科技仍保留如下权利：</p>
      <p>（1）有权在法律、法规等规定时间内保留用户的资料，包括但不限于以前的用户资料、咨询记录等；</p>
      <p>（2）若终止使用、服务前，该用户在本软件上存在违法行为或违反本协议之行为，本软件及尚医科技仍可行使本协议所规定的权利。</p>
      <p>6.3 若用户存在如下情形的，本软件及尚医科技可以终止向该用户提供服务：</p>
      <p>（1）用户违反本协议及相关规则时，本软件及尚医科技有权终止向该用户提供服务；本软件将在终止服务时通知该用户，但该用户在被终止服务后，再一次直接或间接以他人名义注册成为本软件用户的，本软件及尚医科技仍有权单方终止为该用户提供服务；</p>
      <p>（2）本软件及尚医科技发现用户注册资料中主要内容是虚假的；</p>
      <p>（3）本协议终止或更新时，用户未确认新协议的；</p>
      <p>（4）其他本软件及尚医科技认为需要终止提供服务的情形。</p>

      <h3>第7条 适用法律和管辖</h3>
      <p>本协议的履行地为尚医科技所在地（实际营业地）。本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，因本协议产生之争议，由本协议履行地成都市高新区人民法院管辖。</p>

    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: "patientRegister",
    data() {
      return {
      }
    },
    computed:{
      ...mapState({
      })
    },
    created() {
    },
    mounted(){
      document.title = "术康-注册协议"
    }
  }
</script>

<style scoped lang="scss">
  .info_warp {
    padding: 16px 7px;
    /*font-family: "黑体";*/
  }
  .info_warp .title{
    font-size: 24px;
    color: black;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .info_warp .inner {
    color: #000;
  }

  .info_warp .inner h2 {
    font-size: 16px;
    font-weight: bold;
  }

  .info_warp .inner p {
    word-wrap: break-word;
    font-size: 14px;
    text-indent: 2em;
    margin: 5px 0;
  }

  .info_warp .inner .ft12 {
    color: #999;
    display: block;
    text-align: center;
  }

  .mgt15 {
    margin: 15px 0 0 0;
    div{
      margin:5px 0;
    }
  }

  .secLink {
    text-decoration: underline;
  }
  .bold{
    font-weight: bold;
  }
  .intend{
    text-indent: 2em !important;
  }

</style>
